import React from "react";

import "./ImageFrame.scss";

class ImageFrame extends React.Component<{
  width: number;
  height: number;
  url: string;
  index: number;
  currentIndex: number;
  onImageLoaded: (index: number) => void;
  onImageError: (index: number) => void;
  shouldLoad: boolean;
}> {

  public render() {
    const zIndex = (this.props.index === this.props.currentIndex) ? 10
      : (this.props.index === 7) ? 2 : 1;
    return ((this.props.url !== undefined) ?
      <img className="vto-image-frame" src={this.props.url} style={{
        zIndex: zIndex,
        width: this.props.width,
        height: this.props.height
      }}
        onLoad={() => { this.props.onImageLoaded(this.props.index); }}
        onError={() => { this.props.onImageError(this.props.index); }}
      /> : null
    );
  }
}

export { ImageFrame };
