// tslint:disable: max-line-length
import React from "react";

export class RotateIcon extends React.Component {

  public render() {
    return (
      <svg className="vto-image-rotate-bar-arrow" viewBox="0 0 62 13" version="1.1">
        <defs/>
        <g id="VTO-Desktop-Master" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="2.0-Desktop-VTO---Onboarding---Welcome-Screen" transform="translate(-358.000000, -492.000000)" fill="#000000">
            <g id="Drag-to-Rotate" transform="translate(232.000000, 484.000000)">
              <g id="Rotate-Icon" transform="translate(126.000000, 8.000000)">
                <g id="Group">
                  <g id="Group-2">
                    <polygon id="Fill-1" points="55.3812997 -0.000202399732 55.3812997 1.68848603 59.7993295 1.68848603 59.7993295 8.32112524 61.1156405 8.32112524 61.1156405 -0.000202399732"/>
                    <polygon id="Fill-2" points="0 -0.000202399732 0 8.32112524 1.31578509 8.32112524 1.31578509 1.61401779 6.48636822 1.61401779 6.48636822 -0.000202399732"/>
                    <path d="M31.2754017,12.672652 C26.7337869,12.672652 22.622353,12.0276715 19.1351544,11.154654 C8.18079649,8.41146299 1.06914112,3.03572611 0.772011395,2.80768908 L1.57925764,1.06975005 C1.65025324,1.12372331 8.79556579,6.51632684 19.4953913,9.18260597 C29.3369589,11.6356907 44.2991497,12.216578 59.6873142,1.05828073 L60.4640586,2.81983306 C50.0908129,10.3416818 39.9084664,12.672652 31.2754017,12.672652" id="Fill-3"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
