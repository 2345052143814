class VtoImageError extends Error {
  private type: string;

  constructor(type: string, message: string) {
    super(`${type}: ${message}`);
    this.type = type;
    this.message = message;
    Object.setPrototypeOf(this, VtoImageError.prototype);
  }

  public getType() {
    return this.type;
  }
}

class InvalidParametersError extends VtoImageError {
  constructor(parameter: string) {
    super("InvalidParametersError", `${parameter} parameter is invalid`);
    Object.setPrototypeOf(this, InvalidParametersError.prototype);
  }
}

class CatalogError extends VtoImageError {
  constructor(upc: string) {
    super("CatalogError", `Failed to determine catalog status of ${upc}`);
    Object.setPrototypeOf(this, CatalogError.prototype);
  }
}

class UpcUnsupportedError extends VtoImageError {
  constructor(upc: string) {
    super("UpcUnsupportedError", `${upc} is unsupported by vto`);
    Object.setPrototypeOf(this, UpcUnsupportedError.prototype);
  }
}

class UpcMissingError extends VtoImageError {
  constructor(readonly upc: string) {
    super("UpcMissingError", `upc ${upc} could not be found`);
    Object.setPrototypeOf(this, UpcMissingError.prototype);
  }
}

class ImageAvailabilityError extends VtoImageError {
  constructor(videoId: string, upc: string) {
    super("ImageAvailabilityError", `Images are not yet available for videoId:${videoId}, upc:${upc} `);
    Object.setPrototypeOf(this, ImageAvailabilityError.prototype);
  }
}

class ImageApiError extends VtoImageError {
  constructor(response?: Response) {
    if (response) {
      super("ImageApiError", `Image api failed with status: ${response.status} - ${response.statusText}`);
    } else {
      super("ImageApiError", `Image api failed`);
    }
    Object.setPrototypeOf(this, ImageAvailabilityError.prototype);
  }
}

export {
  VtoImageError,
  InvalidParametersError,
  CatalogError,
  UpcUnsupportedError,
  UpcMissingError,
  ImageAvailabilityError,
  ImageApiError
};
