import { defaultOptions } from "../constants/DefaultOptions";
import { InvalidParametersError } from "../errors/errors";
import { RenderImageOptions } from "../VtoImage";

export class ParameterHelper {

  public static mergeRenderImageOptions(
    inputOptions: RenderImageOptions
  ): RenderImageOptions {

    if (!inputOptions) { throw new InvalidParametersError("RenderImageOptions"); }

    if (!inputOptions.divId || typeof inputOptions.divId !== "string") {
      throw new InvalidParametersError("divId");
    }
    if (!inputOptions.videoId || typeof inputOptions.videoId !== "string") {
      throw new InvalidParametersError("videoId");
    }
    if (!inputOptions.upc || typeof inputOptions.upc !== "string") {
      throw new InvalidParametersError("upc");
    }

    return {
      ...defaultOptions,
      ...inputOptions,
    };
  }
}
