const DefaultTrackingOptions: TrackingOptions = {
  debug: false,
  inspect: false,
  recoveryLimit: 1,
  areaSize: 1,
  areaOffset: [0, 0],
  depthRange: [100, 2000],
  axisRange: [7, 50, 7],
  fittedPointsRatio: 0.25,
  fov: 52.0,
  minimumFaceSizeRatio: 0.3,
  edgeDetectionPercentage: [0.15, 0.1, 0.1, 0.1], // top, right, bottom, left
  nudgingType: 2,
  standstill: {
    timeLimit: 1500,
    positionVariance: 50,
    axisVariance: 8,
    depthRange: [150, 500]
  },
  onPoseTrack: () => { return; },
};

export { DefaultTrackingOptions };
