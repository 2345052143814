export class LocalizationHelper {

  private static localeMap = {
    de: "de-DE",
    en: "en-US",
    es: "es-ES",
    fr: "fr-FR",
    it: "it-IT",
    ja: "ja-JP",
    ko: "ko-KR",
    nl: "nl-NL",
    pl: "pl-PL",
    pt: "pt-PT",
    sv: "sv-SE",
    tr: "tr-TR",
    zh: "zh-CN"
  };

  private static translations = {
    "de-DE": { ROTATE: "Zum Drehen klicken und ziehen" },
    "en-US": { ROTATE: "Click and drag to rotate" },
    "es-ES": { ROTATE: "Haz clic y arrastra para girar" },
    "fr-FR": { ROTATE: "Cliquez-glissez pour tourner" },
    "it-IT": { ROTATE: "Clicca e trascina per ruotare" },
    "ja-JP": { ROTATE: "回転するには、クリックしてドラッグ" },
    "ko-KR": { ROTATE: "회전하려면 클릭하고 드래그하세요" },
    "nl-NL": { ROTATE: "Draai door te klikken en te slepen" },
    "pl-PL": { ROTATE: "Kliknij i przeciągnij, aby obrócić" },
    "pt-PT": { ROTATE: "Clique e arraste para rodar" },
    "sv-SE": { ROTATE: "Klicka och dra för att vrida" },
    "tr-TR": { ROTATE: "Döndürmek için tıklayın ve sürükleyin" },
    "zh-CN": { ROTATE: "點選並拖曳即可轉動" },
  };

  public static getString(
    locale: string,
    key: string
  ): string {

    try {
      if (this.translations[locale][key]) {
        return this.translations[locale][key];
      } else if (this.translations[this.localeMap[locale.split("-")[0]]][key]) {
        return this.translations[this.localeMap[locale.split("-")[0]]][key];
      } else {
        return this.translations["en-US"][key];
      }
    } catch (e) {
      return "";
    }
  }
}
