import React from "react";
import { LoadingStatus } from "../constants/LoadingStatus";
import { CatalogError, ImageAvailabilityError, UpcUnsupportedError } from "../errors/errors";
import { CatalogHelper } from "../helpers/CatalogHelper";
import { ImageHelper } from "../helpers/ImageHelper";
import { ErrorType, ImageApiData } from "../types/vtoimage";
import { RenderImageOptions } from "../VtoImage";
import { VtoImageContainer } from "./VtoImageContainer";

class VtoImageLoader extends React.Component<{
  width: number;
  height: number;
  options: RenderImageOptions;
  cacheBust: number;
  onSuccess: () => void;
  onError: (reason) => void;
}, {
  containerStatus: LoadingStatus;
  imageApiData: ImageApiData;
  errorType: ErrorType;
}> {

  public state = {
    containerStatus: LoadingStatus.LOADING,
    imageApiData: undefined,
    errorType: undefined
  };

  public componentWillMount() {
    this.updateVtoImage();
  }

  public componentDidUpdate(prevProps: VtoImageLoader["props"]) {
    if (this.props.options.upc !== prevProps.options.upc
      || this.props.options.videoId !== prevProps.options.videoId) {
      this.updateVtoImage();
    }
  }

  private updateVtoImage() {
    return this.requestImageData(this.props.options)
    .then(() => {
      this.props.onSuccess();
    }).catch((e) => {
      this.props.onError(e);
    });
  }

  private requestImageData = (options: RenderImageOptions) => {
    this.setState({
      imageApiData: {
        upc: options.upc,
        videoId: options.videoId,
        supported: undefined,
        imageUrls: undefined,
        error: undefined
      }
    });

    return Promise.all([
      CatalogHelper.upcIsSupported(options.upc, options.key),
      ImageHelper.getImageUrls(
        options.environment,
        options.videoId,
        options.upc,
        Math.max(this.props.width, this.props.height),
        options.key,
      )
    ]).then((result) => {
      const imageUrls = result[1].slice(0, 15);
      if (options.videoId === this.props.options.videoId
        && options.upc === this.props.options.upc) {
        this.setState({
          imageApiData: {
            upc: options.upc,
            videoId: options.videoId,
            supported: true,
            imageUrls: imageUrls,
            error: undefined
          }
        });
      }
    }).catch((e) => {
      if (e instanceof CatalogError
        || e instanceof UpcUnsupportedError) {
        this.setState({
          imageApiData: {
            upc: options.upc,
            videoId: options.videoId,
            supported: false,
            imageUrls: [],
            error: "UPC_ERROR"
          }
        });
        throw e;
      } else {
        this.setState({
          imageApiData: {
            upc: options.upc,
            videoId: options.videoId,
            supported: undefined,
            imageUrls: [],
            error: "VIDEO_ERROR"
          }
        });
        throw new ImageAvailabilityError(options.videoId, options.upc);
      }
    });
  }

  public render() {
    return (
      <VtoImageContainer
        width={this.props.width}
        height={this.props.height}
        imageApiData={this.state.imageApiData}
        locale={this.props.options.locale}
        videoId={this.props.options.videoId}/>
    );
  }
}

export { VtoImageLoader };
