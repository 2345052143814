import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import { name, version } from "../../../package.json";
import { ICatalogueAdapter } from "../adapter/ICatalogueAdapter";
import { TransitionColor } from "../model/TransitionColor";
import { UpcAvailability } from "../model/UpcAvailability";
import { ICatalogueService } from "./ICatalogueService";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "CatalogueService");

class CatalogueService implements ICatalogueService {

  public catalogueAdapter: ICatalogueAdapter;
  private chunkSize: number = 100;

  constructor(catalogueAdapter: ICatalogueAdapter) {
    this.catalogueAdapter = catalogueAdapter;
  }

  public isUpcSupported(upcs: string[]): { [upc: string]: Promise<UpcAvailability> } {
    return this.processSortedUpcs(upcs.sort());
  }

  public downloadTransitionColorCatalogue(): Promise<{
    [upc: string]: TransitionColor
  }> {
    return this.catalogueAdapter.downloadTransitionColorCatalogue();
  }

  private processSortedUpcs(sortedUpcs: string[]) {
    const time1 = performance.now();
    const res: { [upc: string]: Promise<UpcAvailability> } = {};
    const chunks = this.chunkArray(sortedUpcs, this.chunkSize);
    logger.info("upcs splitted in {} chunk(s)", chunks.length);
    chunks.forEach((chunk) => {
      const adapterResult = this.catalogueAdapter.isUpcSupported(chunk);
      if (adapterResult) {
        Object.keys(adapterResult).forEach((key) => {
          res[key] = adapterResult[key];
        });
      }
    });
    const time2 = performance.now();
    logger.info("upc(s) promise fetched in {} ms: {}", time2 - time1, JSON.stringify(Object.keys(res)));
    return res;
  }

  private chunkArray(upcs: string[], chunkSize: number) {
    const chunks: string[][] = [];
    while (upcs.length) {
      chunks.push(upcs.splice(0, chunkSize));
    }
    return chunks;
  }
}

export { CatalogueService };
