import { Environment } from "./Environment";

declare interface ICatalogueClientConfiguration {
  key?: string;
  env: Environment;
}

class CatalogueClientConfig {

  private privateKey: string | undefined = undefined;
  public env: Environment;

  constructor(params: ICatalogueClientConfiguration) {
    this.privateKey = params.key;
    this.env = params.env;
  }

  public getKey() {
    return this.privateKey;
  }

  public getEnv() {
    return this.env;
  }

}

export { CatalogueClientConfig, ICatalogueClientConfiguration };
