import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import { name, version } from "../../../package.json";
import { ICatalogueAdapter } from "../adapter/ICatalogueAdapter";
import { TransitionColor } from "../model/TransitionColor";
import { UpcAvailability } from "../model/UpcAvailability";
import { ICatalogueService } from "./ICatalogueService";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "CatalogueClient");

class CatalogueMockService implements ICatalogueService {

  public catalogueAdapter!: ICatalogueAdapter;

  public isUpcSupported(upcs: string[]): { [upc: string]: Promise<UpcAvailability>; } {
    logger.warn("Using a mocked isUPCSupported response, will be always returned true");
    const result: { [upc: string]: Promise<UpcAvailability>; } = {};
    upcs.forEach((upc) => {
      result[upc] = new Promise<UpcAvailability>((resolve) => {
        resolve({
          upc: upc,
          available: {
            256: true,
            512: true,
          },
          transitions: {
            256: true,
            512: true,
          },
          getUpc: () => upc,
          isAvailable: () => true,
          isTransition: () => true,
        });
      });
    });
    return result;
  }

  public downloadTransitionColorCatalogue(): Promise<{ [upc: string]: TransitionColor; }> {
    return Promise.resolve({
      "SIGNATURE-GREY": {
        activeOpacity: 0.871,
        clearOpacity: 0.123,
        activeColor: [0.12941176, 0.12941176, 0.12941176],
        clearColor: [0.12941176, 0.12941176, 0.12941176],
      }
    });
  }
}

export { CatalogueMockService };
