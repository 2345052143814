import React from "react";
import { LoadingStatus } from "../constants/LoadingStatus";
import { ErrorType, ImageApiData } from "../types/vtoimage";
import { ErrorOverlay } from "./ErrorOverlay";
import { ImageFrame } from "./ImageFrame";
import { LoadingOverlay } from "./LoadingOverlay";
import { SwipeableContainer } from "./SwipeableContainer";
import "./VtoImageContainer.scss";

class VtoImageContainer extends React.Component<{
  width: number;
  height: number;
  imageApiData: ImageApiData;
  locale: string;
  videoId: string;
}, {
  containerStatus: LoadingStatus;
  currentIndex: number;
  urls: string[];
  error: ErrorType;
}> {

  private readonly indexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  private imagesLoadingStatus = new Array<LoadingStatus>(15).fill(LoadingStatus.LOADING);

  public state = {
    containerStatus: LoadingStatus.LOADING,
    currentIndex: 7,
    urls: [],
    error: undefined
  };

  public componentWillMount() {
    this.updateContainerStatus();
  }

  private onImageLoaded = (index: number) => {
    this.imagesLoadingStatus[index] = LoadingStatus.LOADED;
    this.updateContainerStatus();
  }

  private onImageError = (index: number) => {
    this.imagesLoadingStatus[index] = LoadingStatus.ERROR;
    this.updateContainerStatus();
  }

  private updateContainerStatus = () => {
    if (this.props.imageApiData.error) {
      this.setState({
        error: this.props.imageApiData.error,
        containerStatus: LoadingStatus.ERROR
      });
    } else {
      let status: LoadingStatus;
      if (this.imagesLoadingStatus.some((s) => s === LoadingStatus.ERROR)) {
        status = LoadingStatus.ERROR;
      } else if (this.imagesLoadingStatus.every((x) => x === LoadingStatus.LOADED)) {
        status = LoadingStatus.LOADED;
      } else {
        status = LoadingStatus.LOADING;
      }
      this.setState({
        error: undefined,
        containerStatus: status,
      });
    }
  }

  public componentDidUpdate(prevProps: VtoImageContainer["props"]) {
    if (JSON.stringify(this.props.imageApiData) !== JSON.stringify(prevProps.imageApiData)
      || this.props.videoId !== prevProps.videoId) {
      this.imagesLoadingStatus = new Array<LoadingStatus>(15).fill(LoadingStatus.LOADING);
      this.updateContainerStatus();
    }

    if ((this.props.imageApiData && this.props.imageApiData.imageUrls && !this.state.urls)
      || (this.props.imageApiData && this.props.imageApiData.imageUrls
        && this.state.urls !== this.props.imageApiData.imageUrls)) {
      this.setState({ urls: this.props.imageApiData.imageUrls});
    }
  }

  public render() {
    const middleLoaded = this.imagesLoadingStatus[7] === LoadingStatus.LOADED;

    return (
      <SwipeableContainer containerStatus={this.state.containerStatus}
        width={this.props.width} height={this.props.height}
        locale={this.props.locale}
        onNewIndex={(index) => this.setState({currentIndex: index})}>
        <LoadingOverlay width={this.props.width} height={this.props.height}
          containerStatus={this.state.containerStatus} errorType={this.state.error} middleLoaded={middleLoaded}/>
        <ErrorOverlay width={this.props.width} height={this.props.height}
          containerStatus={this.state.containerStatus} errorType={this.state.error}/>
        <div className="vto-image-frame-container">
        {(this.state.urls) &&
          this.indexes.map((index) => { return (
            <ImageFrame key={index} url={this.state.urls[index]}
              width={this.props.width} height={this.props.height}
              index={index} currentIndex={this.state.currentIndex}
              onImageLoaded={this.onImageLoaded} onImageError={this.onImageError}
              shouldLoad={index === 7 || middleLoaded}/>
          ); })
        }
        </div>
      </SwipeableContainer>
    );
  }
}

export { VtoImageContainer };
