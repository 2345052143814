import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import { name, version } from "../package.json";
import { CatalogueAdapter } from "./adapter/CatalogueAdapter";
import { CatalogueClientConfig, ICatalogueClientConfiguration } from "./domain/model/CatalogueClientConfig";
import { Environment } from "./domain/model/Environment";
import { TransitionColor } from "./domain/model/TransitionColor";
import { UpcAvailability } from "./domain/model/UpcAvailability";
import { CatalogueMockService } from "./domain/service/CatalogueMockService";
import { CatalogueService } from "./domain/service/CatalogueService";
import { ICatalogueService } from "./domain/service/ICatalogueService";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "CatalogueClient");

class CatalogueClient {

  private catalogueService: ICatalogueService;

  constructor(catalogueService: ICatalogueService) {
    this.catalogueService = catalogueService;
  }

  public static buildNewClient(config: ICatalogueClientConfiguration) {
    return new CatalogueClient(new CatalogueService(new CatalogueAdapter(new CatalogueClientConfig(config))));
  }

  public static buildNewMockClient() {
    return new CatalogueClient(new CatalogueMockService());
  }

  public isUpcSupported(...upcs: string[]): { [upc: string]: Promise<UpcAvailability> } {
    logger.info("isUpcSupported for {}", JSON.stringify(upcs));
    const isUpcSupportedResponse = this.catalogueService.isUpcSupported(upcs);
    if (isUpcSupportedResponse) {
      Object.keys(isUpcSupportedResponse).forEach((itemKey) => {
        isUpcSupportedResponse[itemKey].then((upcAvailability) => {
          logger.info("upcAvailability promise solved for upc {}", JSON.stringify(upcAvailability));
        });
      });
    }
    return isUpcSupportedResponse;
  }

  public downloadTransitionColorCatalogue(): Promise<{ [name: string]: TransitionColor }> {
    return this.catalogueService.downloadTransitionColorCatalogue();
  }

  public getEnv(): Environment {
    return this.catalogueService.catalogueAdapter.catalogueClientConfig.env;
  }
}

export {
  CatalogueClient,
  UpcAvailability,
  TransitionColor,
  ICatalogueClientConfiguration,
  Environment as CatalogueEnvironment,
};
