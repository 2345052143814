import * as Hammer from "hammerjs";
import React from "react";
import { LoadingStatus } from "../constants/LoadingStatus";
import { LocalizationHelper } from "../helpers/LocalizationHelper";
import { MathHelper } from "../helpers/MathHelper";
import { RotateIcon } from "./icons/RotateIcon";

import "./SwipeableContainer.scss";

export class SwipeableContainer extends React.Component<{
  containerStatus: LoadingStatus;
  width: number;
  height: number;
  locale: string;
  onNewIndex: (index: number) => void;
}, {
  index: number;
  swiped: boolean;
}> {

  private hammerManager: HammerManager;
  private containerRef: EventTarget;

  private readonly indexCount = 15;
  private readonly swipeSpeedModifier = 0.03;
  private readonly indexInterval = Math.round(this.props.width * this.swipeSpeedModifier);

  private previousX = undefined;

  constructor(props) {
    super(props);
    this.state = {
      index: 7,
      swiped: false
    };
  }

  public componentDidMount() {
    this.hammerManager = new Hammer.Manager(this.containerRef, {
      recognizers: [
        [Hammer.Pan, {
          direction: Hammer.DIRECTION_HORIZONTAL,
          pointers: 1
        }]
      ]
    });
    this.hammerManager.on("panmove", this.computeIndex);
    this.hammerManager.on("panend", () => {
      this.previousX = undefined;
      if (!this.state.swiped) { this.setState({swiped: true}); }
    });
  }

  public componentWillUnmount() {
    if (this.hammerManager) {
      this.hammerManager.destroy();
    }
  }

  private computeIndex = (
    event: HammerInput
  ): void => {
    if (this.props.containerStatus === LoadingStatus.LOADED) {
      const oldIndex = this.state.index;
      if (this.previousX === undefined) {
        this.previousX = event.center.x;
      }
      const deltaX = this.previousX - event.center.x;
      const deltaIndex = Math.round(deltaX / this.indexInterval);

      const index = MathHelper.clamp(
        this.state.index + deltaIndex,
        0, this.indexCount - 1);

      this.setState({
        index
      });

      if (oldIndex !== index) {
        this.previousX = event.center.x;
        this.props.onNewIndex(index);
      }
    }
  }

  public render() {
    return (
      <div className="vto-image-swipeable-container"
        ref={(ref) => this.containerRef = ref}
        style={{
          display: "inline-flex",
          width: this.props.width,
          height: this.props.height
        }}>
        {this.props.children}
        {this.props.containerStatus === LoadingStatus.LOADED &&
          <div className={`vto-image-rotate-bar${(this.state.swiped) ? " hidden" : ""}`}
            style={{ width: this.props.width }}>
            <RotateIcon/>
            <div className="vto-image-rotate-bar-text">
              {LocalizationHelper.getString(this.props.locale, "ROTATE")}
            </div>
          </div>
        }
      </div>
    );
  }
}
