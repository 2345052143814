import { CatalogueClient, CatalogueEnvironment } from "@luxottica/vm-catalogue-client";
import { CatalogError, UpcUnsupportedError } from "../errors/errors";

export class CatalogHelper {

  private static catalog: CatalogueClient;

  public static upcIsSupported(
    upc: string,
    key: string
  ): Promise<void> {
    if (this.catalog === undefined) {
      this.catalog = CatalogueClient.buildNewClient({key: key, env: CatalogueEnvironment.PROD});
    }
    const res = this.catalog.isUpcSupported(upc);
    return res[upc]
    .then((availability) => {
      if (availability.isAvailable()) {
        return;
      } else {
        throw new UpcUnsupportedError(upc);
      }
    }).catch(() => {
      throw new CatalogError(upc);
    });
  }
}
