import { Config } from "../constants/Config";
import { ImageApiError, ImageAvailabilityError } from "../errors/errors";
import { ImageApiEnvironment, ImageApiResponse } from "../types/vtoimage";

export class ImageHelper {
  public static getImageUrls(
    environment: ImageApiEnvironment,
    videoId: string,
    upc: string,
    resolution: number,
    key: string,
  ): Promise<string[]> {
    return this.requestImages(
      environment,
      videoId,
      upc,
      resolution,
      key,
    ).then((response) => {
      if (response.available) {
        return response.images
          .sort((a, b) => a.pose - b.pose)
          .map((image) => image.url);
      } else {
        return Promise.reject(new ImageAvailabilityError(videoId, upc));
      }
    });
  }

  private static requestImages(
    environment: ImageApiEnvironment,
    videoId: string,
    upc: string,
    resolution: number,
    key: string,
  ): Promise<ImageApiResponse> {

    const url = this.getApiUrl(environment, key !== undefined);
    return new Promise((resolve, reject) => {
      return this.composeFetch(
        url,
        videoId,
        upc,
        resolution,
        key,
      ).then((response: Response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(new ImageApiError(response));
        }
      }).catch(() => {
        reject(new ImageApiError());
      });
    });
  }

  private static getApiUrl(environment: ImageApiEnvironment, isAuth: boolean): string {
    return (environment === "PROD") ?
      (isAuth ? Config.prodBaseAuthenticatedUrl : Config.prodBaseUrl) :
      (isAuth ? Config.testBaseAuthenticatedUrl : Config.testBaseUrl);
  }

  private static composeFetch(
    url: string,
    videoId: string,
    upc: string,
    resolution: number,
    key: string,
  ): Promise<Response> {
    const headers: HeadersInit = new Headers();
    headers.set("Accept", "application/json");

    if (key) {
      headers.append("x-api-key", key);
    }

    return fetch(`${url}/${videoId}/${upc}${(resolution) ? "?resolution=" + resolution : ""}`, {
      method: "GET",
      mode: "cors",
      credentials: "omit",
      headers,
    });
  }
}
