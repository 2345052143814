// tslint:disable: max-line-length
import React from "react";

export class ErrorIcon extends React.Component {

  public render() {
    return (
      <svg className="vto-image-error-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="red" d="M228.9 79.9L51.8 403.1C40.6 423.3 55.5 448 78.9 448h354.3c23.3 0 38.2-24.7 27.1-44.9L283.1 79.9c-11.7-21.2-42.5-21.2-54.2 0zM273.6 214L270 336h-28l-3.6-122h35.2zM256 402.4c-10.7 0-19.1-8.1-19.1-18.4s8.4-18.4 19.1-18.4 19.1 8.1 19.1 18.4-8.4 18.4-19.1 18.4z"/>
      </svg>
    );
  }
}
