import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import { name, version } from "../../package.json";
import { ICatalogueAdapter } from "../domain/adapter/ICatalogueAdapter";
import { CatalogueClientConfig } from "../domain/model/CatalogueClientConfig";
import { TransitionColor } from "../domain/model/TransitionColor";
import { UpcAvailability } from "../domain/model/UpcAvailability";
import { CatalogueApiConfig } from "./CatalogueApiConfig";
import { UpcsEncoder } from "./UpcsEncoder";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "CatalogueAdapter");

class CatalogueAdapter implements ICatalogueAdapter {

  public catalogueClientConfig: CatalogueClientConfig;
  private catalogueApiConfig = new CatalogueApiConfig();
  private upcsEncoder = new UpcsEncoder();

  constructor(catalogueClientConfig: CatalogueClientConfig) {
    this.catalogueClientConfig = catalogueClientConfig;
  }

  public isUpcSupported(upcs: string[]): { [upc: string]: Promise<UpcAvailability> } {
    const encodedUpcs = this.upcsEncoder.encodeUpcs(upcs);
    const httpResponsePromise: Promise<{ [upc: string]: CatalogResponse }>
      = new Promise((resolve, reject) => {
        this.fetchUpcAvailabilities(encodedUpcs)
          .then((res) => {
            if (res.body && res.ok) {
              res.json().then((json) => {
                const mapObj: { [upc: string]: CatalogResponse } = {};
                Object.assign(mapObj, json);
                logger.debug("[res] json: {}", JSON.stringify(json));
                resolve(mapObj);
              });
            } else {
              logger.warn("isUpcSupported no content response: no upc found");
              resolve({});
            }
          }).catch((e) => {
            logger.error("isUpcSupported error {}", e.message, e);
            reject(e);
          });
      });
    const upcAvailabilityResponse: { [upc: string]: Promise<UpcAvailability> } = {};
    upcs.forEach((upc) => {
      // What is this??
      // upcAvailabilityResponse[upc] = Promise.resolve(new UpcAvailability(upc, true));
      upcAvailabilityResponse[upc] = new Promise((resolve, reject) => {
        httpResponsePromise.then((upcsMap) => {
          if (upcsMap[upc]) {
            logger.debug("upc {} is available response field: {}", upc, upcsMap[upc].available);
            const isAvailable = upcsMap[upc].resolutions.includes("PX_256") || upcsMap[upc].resolutions.includes("PX_512");
            const isTransition = upcsMap[upc].transitionsResolutions.includes("PX_256") ||
              upcsMap[upc].transitionsResolutions.includes("PX_512");
            resolve({
              upc: upc,
              available: {
                256: upcsMap[upc].resolutions.includes("PX_256"),
                512: upcsMap[upc].resolutions.includes("PX_512"),
              },
              transitions: {
                256: upcsMap[upc].transitionsResolutions.includes("PX_256"),
                512: upcsMap[upc].transitionsResolutions.includes("PX_512"),
              },
              getUpc: () => upc,
              isAvailable: () => isAvailable,
              isTransition: () => isTransition,
            });
          } else {
            logger.warn("upc {} not found on response", upc);
            resolve({
              upc: upc,
              available: {
                256: false,
                512: false,
              },
              transitions: {
                256: false,
                512: false,
              },
              getUpc: () => upc,
              isAvailable: () => false,
              isTransition: () => false,
            });
          }
        }).catch((e) => {
          logger.error("isUpcSupported error {}", e.message, e);
          reject(e);
        });
      });
    });
    return upcAvailabilityResponse;
  }

  public downloadTransitionColorCatalogue(): Promise<{
    [upc: string]: TransitionColor
  }> {
    const apiEndpoint = this.catalogueApiConfig.getTransitionColorApiEndpoint(this.catalogueClientConfig.getEnv());
    return this.fetchFrom(apiEndpoint).then((response) => {
      return response.json();
    }).then((json) => {
      try {
        const result: {
          [upc: string]: TransitionColor
        } = {};

        (json as any[]).forEach((color: any) => {
          result[color.name] = {
            activeOpacity: color.active,
            clearOpacity: color.clear,
            activeColor: color.activeColor,
            clearColor: color.clearColor
          };
        });
        return result;
      } catch (e) {
        logger.error(e);
      }
      return {};
    });
  }

  private fetchUpcAvailabilities(upcs: string[]) {
    const apiEndpoint = this.getApiUrl(upcs);
    logger.debug("calling {}", apiEndpoint);
    return this.fetchFrom(apiEndpoint);
  }

  private fetchFrom(apiEndpoint: string) {
    const headers: HeadersInit = new Headers();
    headers.set("Accept", "application/json");
    if (this.catalogueClientConfig.getKey()) {
      headers.append("x-api-key", this.catalogueClientConfig.getKey()!);
    }

    const init: RequestInit = {
      headers,
      method: "GET",
      mode: "cors", // this.config.getKey() ? "no-cors" : "cors",
      referrerPolicy: "unsafe-url",
    };

    const request = fetch(apiEndpoint, init);
    return request;
  }

  private getApiUrl(upcs: string[]) {
    const upcQueryParam = upcs.join(",");
    const apiUrl = (this.catalogueClientConfig.getKey() ?
      this.catalogueApiConfig.getUpcsAvailabilityAuthenticatedApiEndpoint(this.catalogueClientConfig.getEnv()) :
      this.catalogueApiConfig.getUpcsAvailabilityApiEndpoint(this.catalogueClientConfig.getEnv())
    ).replace("{upcs}", upcQueryParam);
    return apiUrl;
  }

}

export { CatalogueAdapter };
