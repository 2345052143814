import React from "react";
import { LoadingStatus } from "../constants/LoadingStatus";
import { ErrorType } from "../types/vtoimage";

import "./LoadingOverlay.scss";

export class LoadingOverlay extends React.Component<{
  width: number;
  height: number;
  containerStatus: LoadingStatus;
  errorType: ErrorType;
  middleLoaded: boolean;
}> {

  public render() {
    const size = (this.props.width <= this.props.height) ? this.props.width : this.props.height;
    const hidden = this.props.containerStatus !== LoadingStatus.LOADING
      || this.props.errorType !== undefined;
    const smallSpinner = this.props.middleLoaded;

    return (
      <div className={`loading-background${(hidden ? " hidden" : "")}${(smallSpinner ? " small" : "")}`}
        style={{ height: this.props.height, width: this.props.width }}>
        <div className="loading-spinner" style={{
          width: size,
          height: size,
        }}>
          <div className={`loader-spinner${(smallSpinner ? " small" : "")}`}/>
        </div>
      </div>
    );
  }
}
