import React from "react";
import { LoadingStatus } from "../constants/LoadingStatus";
import { ErrorType } from "../types/vtoimage";
import "./ErrorOverlay.scss";
import { ErrorIcon } from "./icons/ErrorIcon";

export class ErrorOverlay extends React.Component<{
  width: number;
  height: number;
  containerStatus: LoadingStatus;
  errorType: ErrorType;
}> {

  private text = {
    UPC_ERROR: "UPC is not currently supported",
    VIDEO_ERROR: "Video assets are not available",
  };

  public render() {
    const error = this.props.containerStatus === LoadingStatus.ERROR || this.props.errorType !== undefined;

    return (
      <div className={`error-bg${(!error ? " hidden" : "")}`}
        style={{ height: this.props.height, width: this.props.width }}>
          <ErrorIcon/>
          <div className="error-text">
            {this.text.VIDEO_ERROR}
          </div>
      </div>
    );
  }
}
