import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import React from "react";
import { render } from "react-dom";
import { version } from "../package.json";
import { VtoImageLoader } from "./app/VtoImageLoader.js";
import { UpcUnsupportedError } from "./errors/errors.js";
import { CatalogHelper } from "./helpers/CatalogHelper.js";
import { Catalogue, UpcAvailability } from "./helpers/Catalogue";
import { ParameterHelper } from "./helpers/ParameterHelper";
import { ImageApiEnvironment } from "./types/vtoimage";

interface RenderImageOptions {
  divId: string;
  videoId: string;
  upc: string;
  environment?: ImageApiEnvironment;
  locale?: string;
  key?: string;
}

interface CloseImageOptions {
  divId: string;
}

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "VtoImage");
let cacheBust = 0;

class VtoImage {

  private static key: string = undefined;

  public static version(): string {
    return "v" + version;
  }

  public static setKey(key): void {
    this.key = key;
  }

  public static upcSupported(upc): Promise<void> {
    return new Promise((resolve, reject) => {
      CatalogHelper.upcIsSupported(upc, this.key)
      .then(() => {
        resolve();
      }).catch((e) => {
        reject(new UpcUnsupportedError(upc));
      });
    });
  }

  public static renderImage(options: RenderImageOptions): Promise<void> {
    options.key = this.key;
    return new Promise((resolve, reject) => {
      try {
        const targetDiv = document.getElementById(options.divId);
        const mergedOptions = ParameterHelper.mergeRenderImageOptions(options);
        render(<VtoImageLoader
          width={targetDiv.offsetWidth}
          height={targetDiv.offsetHeight}
          options={mergedOptions}
          cacheBust={cacheBust++}
          onSuccess={resolve}
          onError={reject}/>,
          targetDiv);
      } catch (e) {
        reject(e);
      }
    });
  }

  public static closeImage(options: CloseImageOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const targetDiv = document.getElementById(options.divId);
        render(null, targetDiv);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
}

export {
  VtoImage,
  RenderImageOptions,
  CloseImageOptions,
  Catalogue,
  Catalogue as VirtualMirrorCatalogue,
  UpcAvailability,
};
