import { RemoteLogConfig } from "@luxottica/vm-remotelog";
import { name, version } from "../../package.json";
import { Environment } from "../domain/model/Environment";

const logger = RemoteLogConfig.getInstance().getLoggerInfo(name, version, "CatalogueApiConfig");

class CatalogueApiConfig {
  private hostTest = "https://vmcore-test.luxottica.com";
  private hostProd = "https://vmcore.luxottica.com";
  private upcsAvailabilityApi = "/api/upc/{upcs}/availability";
  private upcsAvailabilityAuthenticatedApi = "/authenticated/upc/{upcs}/availability";
  private transitionColorApi = "/api/transition/vocabulary";

  public getUpcsAvailabilityApiEndpoint(env: Environment) {
    return this.getHostname(env) + this.upcsAvailabilityApi;
  }

  public getUpcsAvailabilityAuthenticatedApiEndpoint(env: Environment) {
    return this.getHostname(env) + this.upcsAvailabilityAuthenticatedApi;
  }

  public getTransitionColorApiEndpoint(env: Environment) {
    return this.getHostname(env) + this.transitionColorApi;
  }

  private getHostname(env: Environment) {
    const host = env === Environment.TEST ? this.hostTest : this.hostProd;
    logger.debug("catalogue api hostname: {}", host);
    return host;
  }
}

export { CatalogueApiConfig };
